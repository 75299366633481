// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../src/assets/images/purple-background-pattern.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* import "chart.js/dist/Chart.css"; */

.App {
  font-family: "Kumbh Sans", sans-serif;
}
.sign-in-page {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,sCAAsC;;AAEtC;EACE,qCAAqC;AACvC;AACA;EACE,mDAAqE;EACrE,sBAAsB;AACxB","sourcesContent":["/* import \"chart.js/dist/Chart.css\"; */\n\n.App {\n  font-family: \"Kumbh Sans\", sans-serif;\n}\n.sign-in-page {\n  background: url(\"../src/assets/images/purple-background-pattern.jpg\");\n  background-size: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
