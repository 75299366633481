import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useGetToken from "../../hooks/useGetToken";
import { useGetData } from "../../hooks/useGetData";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import pennyIcon from "../../assets/images/penny-icon.png";
import treasureIcon from "../../assets/images/treasure-icon.png";
import stashIcon from "../../assets/images/stash-icon.png";
import safeboxIcon from "../../assets/images/safebox-icon.png";

const TransactionHistory = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const { token } = useGetToken("token");

  const { fetchData } = useGetData(
    token,
    `/admin/get-user-transaction-history/${id}`,
    setData
  );
  
  return (
    <div className="p-4">
      <div className="flex items-center">
        <ArrowBackOutlinedIcon
          className="cursor-pointer text-black"
          onClick={() => window.history.back()}
        />
        <h1 className="font-bold text-2xl ml-3">Transaction History</h1>
      </div>

      {/* The history table */}
      {data && (
        <div className="flex flex-col mt-5">
          <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
            <div className="py-2 inline-block min-w-full">
              <div className="overflow-hidden">
                <table className="min-w-full">
                  <thead className="bg-gray-200 border-b">
                    <tr>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-3 py-4 text-left"
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-3 py-4 text-left"
                      >
                        Plan
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        From/To
                      </th>

                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-3 py-4 text-left"
                      >
                        Amount
                      </th>

                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-3  py-4 text-left"
                      >
                        Refernce ID
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.details?.map((item, index) => (
                      <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                        <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {index + 1}
                        </td>
                        <td className=" text-gray-900 px-3 py-4 whitespace-nowrap font-bold text-lg flex items-center">
                          <img
                            className="h-9 w-9 p-2 bg-gray-200 rounded-lg mr-1"
                            alt={"icon"}
                            src={
                              item.type === "penny"
                                ? pennyIcon
                                : item.type === "treasure"
                                ? treasureIcon
                                : item.type === "safebox"
                                ? safeboxIcon
                                : stashIcon
                            }
                          />
                          <div className="ml-2">
                            {item.type}
                            <span className="block text-gray-500 mt-1 text-sm font-medium">
                              {item.date}
                            </span>
                          </div>
                        </td>

                        <td className="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                          {item.credit ? item.from : item.to}
                          <span className="block text-gray-500 mt-1 text-sm font-medium">
                            {!item.credit
                              ? `Bank Name: ${item.bankName}`
                              : `Account No: ${item.accNo}`}
                          </span>
                        </td>

                        <td
                          style={{ color: item.credit ? "green" : "red" }}
                          className="text-lg text-gray-900 px-3 py-4 whitespace-nowrap font-semibold"
                        >
                          {item.amount}
                        </td>

                        <td className="text-sm text-gray-900 px-3 py-4 whitespace-nowrap font-medium">
                          {item.reference}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransactionHistory;
