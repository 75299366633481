import axios from "axios";
import { url } from "../url";

export default async function getCustomerDetails(limit, token) {
  try {
    //  Fetch data from backend using axios
    let response;
    if (limit === null || isNaN(limit)) {
      response = await axios.get(`${url}/admin/customers-details`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      response = await axios.get(
        `${url}/admin/customers-details?limit=${encodeURIComponent(limit)}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }

    const receivedData = response.data;

    if (receivedData.success) {
      return { success: true, message: receivedData.details };
    } else {
      return { success: false, message: "Could not get data" };
    }
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  }
}
