import axios from "axios";
import { url } from "../url";

export default async function getAppDetails(token) {
  try {
    //  Fetch data from backend using axios
    const response = await axios.get(`${url}/admin/app-details`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const receivedData = response.data;
    console.log(receivedData);

    if (receivedData.success) {
      return { success: true, message: receivedData.details };
    } else {
      return { success: false, message: "Could not get data" };
    }
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  }
}
