import React from "react";
import Sidebar from "../component/ui/SideBar";
import Header from "../component/ui/Header";

const Documentation = () => {
  const docs = [
    {
      context: "isTabBarShown, setTabBarShown",
      values: "true or false",
      type: "boolean",
      description:
        "This states stores the state of the tab bar. It is used to determine the visibility of the tab bar, when set to true it shows the tab bar, and when set to false it hides the tab bar",
    },
  ];
  return (
    <div className="flex h-screen relative overflow-y-hidden">
      <Sidebar />
      <div className="flex-1 bg-gray-200 p-5 pt-6 overflow-y-auto">
        <Header title={"Frontend-Docs."} />

        <div className="mt-10">
          <h1 className="mt-3 mb-2 font-semibold text-2xl">Global context</h1>
          <p className="text-gray-700 text-lg">
            Context location:{"   "}JesekuApp\Appcontext.js
          </p>
          <p className="text-gray-700 text-lg">
            {"   "}Context Name: AppContext
          </p>
        </div>

        <div className="mt-10 p-5 bg-white rounded-2xl">
          <table className="w-full ">
            <thead className="my-5">
              <tr className="flex justify-between py-5">
                <th>Context</th>
                <th>Values</th>
                <th className="basis-1/4">Type</th>
                <th className="basis-1/4">Description</th>
              </tr>
            </thead>

            {docs.map((doc, index) => {
              return (
                <tr className="flex justify-between py-5 border-gray-200 border-[1px] border-collapse my">
                  <td className="basis-1/4">{doc.context}</td>
                  <td className="basis-1/4">{doc.values}</td>
                  <td className="basis-1/4">
                    <code>{doc.type}</code>
                  </td>
                  <td className="basis-1/4">{doc.description}</td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Documentation;
