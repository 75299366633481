import { CircularProgress } from "@mui/material";
import React, { useContext } from "react";
import { AppContext } from "../../AppContext";

const Loading = () => {
  const { showLoading } = useContext(AppContext);

  return (
    <div
      style={{ display: showLoading ? "flex" : "none" }}
      className="text-white  items-center justify-center absolute z-[999px] h-[100dvh] w-[100dvw] top-0 bg-[#00000090]"
    >
      <CircularProgress color="inherit" size={30} />
    </div>
  );
};

export default Loading;
