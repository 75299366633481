/* eslint-disable no-unused-vars */
import React from 'react'
import { useEffect } from "react"
import { useCookies } from "react-cookie"
import { useNavigate } from "react-router-dom"

/**
 * The Logout component is a React component that deletes a token cookie and navigates to the signIn
 * page.
 */
const Logout = () => {
    const navigate = useNavigate()
    const [cookie, setCookie, removeCookie] = useCookies(['token'])

    const deleteCookie = () => {
        removeCookie('token', { path: '/' })
        navigate('/')

    }

    //   delete the token cookie
    useEffect(() => {
        deleteCookie()
    })

    return (
        // navigate to signIn Page
        <div>Logging you out...</div>
    )
}

export default Logout