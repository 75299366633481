import React, { useState } from "react";
import useGetToken from "../../hooks/useGetToken";
import { useGetData } from "../../hooks/useGetData";
import treasureIcon from "../../assets/images/treasure-icon.png";
import safeboxIcon from "../../assets/images/safebox-icon.png";
import { useNavigate } from "react-router-dom";

const TopSavers = () => {
  const [data, setData] = useState([]);
  const { token } = useGetToken("token");

  const { fetchData } = useGetData(
    token,
    `/admin/get-users-with-savings`,
    setData
  );

  const sortedData =
    data && data?.users?.sort((a, b) => b.total_amount - a.total_amount);

  const navigate = useNavigate();

  return (
    <div className="flex flex-col">
      <div class="overflow-x-auto">
        <div class=" inline-block min-w-full">
          <div class="overflow-hidden">
            <table class="min-w-full">
              <thead class="bg-gray-200 border-b">
                <tr>
                  <th
                    scope="col"
                    class="text-base font-semibold text-gray-900 px-6 py-4 text-left"
                  >
                    #ID
                  </th>
                  <th
                    scope="col"
                    class="text-base font-semibold text-gray-900 px-6 py-4 text-left"
                  >
                    Username
                  </th>
                  <th
                    scope="col"
                    class="text-base font-semibold text-gray-900 px-6 py-4 text-left"
                  >
                    Plan
                  </th>
                  <th
                    scope="col"
                    class="text-base font-semibold text-gray-900 px-6 py-4 text-left"
                  >
                    Amount
                  </th>
                </tr>
              </thead>

              <tbody>
                {data &&
                  sortedData?.map((user, index) => {
                    if (index < 4) {
                      return (
                        <tr
                          onClick={() => navigate(`/details/${user.id}`)}
                          key={index}
                          class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                        >
                          <td class="px-6 py-4 text-base whitespace-nowrap font-semibold text-gray-900">
                            {user.id}
                          </td>
                          <td class="text-base text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                            {user.username}
                          </td>
                          <td
                            class={`text-plan ${
                              user.plan === "treasure"
                                ? "text-orange-400"
                                : "text-gray-900"
                            } font-medium px-6 py-3 text-lg whitespace-nowrap flex items-center rounded-sm  ${
                              user.plan === "treasure"
                                ? "bg-orange-50"
                                : "bg-gray-100"
                            } my-2`}
                          >
                            <img
                              className="h-6 w-6 mr-3"
                              alt="plan-logo"
                              src={
                                user.plan === "treasure"
                                  ? treasureIcon
                                  : safeboxIcon
                              }
                            />
                            {user.plan}
                          </td>
                          <td class="text-lg font-semibold text-green-950 px-6 py-4 whitespace-nowrap">
                            ₦{user.total_amount.toFixed(2).toLocaleString()}
                          </td>
                        </tr>
                      );
                    }
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSavers;
