import axios from "axios";
import { url } from "../component/url";
import { useContext, useEffect } from "react";
import { AppContext } from "../AppContext";

export const useGetData = (token, path, setData) => {
  const { setShowLoading } = useContext(AppContext);

  const fetchData = async (e) => {
    try {
      setShowLoading(true);
      const paths = url + path;
      const response = await axios.get(paths, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setData(response.data);
      // console.log(response.data);
    } catch (error) {
      if (error.response) {
        alert(error.response.data.message, "response error");
      } else {
        alert(error.message);
      }
    } finally {
      setShowLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return { fetchData };
};
