import React from "react";

const SingleHistory = ({ data }) => {
  return (
    <div className="md:flex md:justify-between md:items-center border-b-gray-300 border-b-[1px] py-4 text-lg px-2">
      <div>
        <b className="text-xl font-semibold">{data.balance_type}</b>
        <p className="text-xs text-gray-500 font-medium">
          {new Date(data.time).toDateString()}
          {` | `}
          {new Date(data.time).toLocaleTimeString()}
        </p>
      </div>

      <p className="text-gray-500 text-sm">{data.transaction_date}</p>
      <p className="text-medium text-base text-gray-600">
        {data.operation === "withdraw" ? "Debited " : "Credited "}
        {data.account_name}
      </p>

      <p
        style={{ color: data.operation === "withdraw" ? "red" : "green" }}
        className="text-gray-500 font-semibold text-lg"
      >
        ₦{data.amount}
      </p>
    </div>
  );
};

export default SingleHistory;
