import { Outlet } from "react-router-dom";
import SignInPage from "../../pages/auth/SignInPage";
import { useCookies } from "react-cookie";

const useAuth = () => {
  const [cookies] = useCookies(["token"]);
  // const token = Cookies.get('token')
  const token = cookies.token;

  let userLoggedIn;
  if (token !== null && token !== undefined) {
    userLoggedIn = true;
  } else {
    userLoggedIn = false;
  }

  const user = { loggedIn: userLoggedIn };
  return user && user.loggedIn;
};

const ProtectedRoutes = () => {
  const isAuth = useAuth();

  return isAuth ? <Outlet /> : <SignInPage />;
};

export default ProtectedRoutes;
