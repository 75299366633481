import React, { createContext, useState } from "react";
import { customerInfoInitialState } from "./component/functions/details";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [customerInfo, setCustomerInfo] = useState(customerInfoInitialState);
  const [showLoading, setShowLoading] = useState(false);

  return (
    <AppContext.Provider
      value={{
        customerInfo,
        setCustomerInfo,
        showLoading,
        setShowLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
