import axios from "axios";
import { url } from "../url";
import { useContext, useEffect } from "react";
import { AppContext } from "../../AppContext";

const ModifyUserBalance = (
  token,
  accountNumber,
  operation,
  amount,
  pin,
  balanceType
) => {
  const { setShowLoading } = useContext(AppContext);

  const modifyBalance = async (e) => {
    try {
      setShowLoading(true);

      if (pin === "080359") {
        const response = await axios.post(
          `${url}/admin/modify-user-balance`,
          {
            accountNumber,
            operation,
            amount,
            balanceType,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        alert(response.data.message, "success");
      } else {
        alert("Wrong pin");
        setShowLoading(false);
      }
    } catch (error) {
      console.log(error.message, "failed");
      alert(error.message || "Something went wrong");
    } finally {
      setShowLoading(false);
    }
  };

  return { modifyBalance };
};

export default ModifyUserBalance;
