/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { useParams } from "react-router-dom";
// import { customerInfo } from "../component/functions/details";
// import { AppContext } from "../AppContext";
import HideImageRoundedIcon from "@mui/icons-material/HideImageRounded";
import Sidebar from "../component/ui/SideBar";
import Header from "../component/ui/Header";
import eachCustomerDetails from "../component/api/eachCustomerDetails";
import { CircularProgress } from "@mui/material";
import { Error } from "@mui/icons-material";
import axios from "axios";
import { url } from "../component/url";
import { useCookies } from 'react-cookie'

const UserVerification = () => {
  const [confirmRejectOpen, setConfirmRejectOpen] = useState(false);

  const [confirmRejectBvnOpen, setConfirmRejectBvnOpen] = useState(false)

  const { id } = useParams();

  // const { customerInfo } = useContext(AppContext)

  // const list = customerInfo.filter((item) => item.id === id);
  // const details = list[0];

  const [details, setDetails] = useState([])

  const [bvnLoading, setBvnLoading] = useState(false)

  const [bvnGetSuccess, setBvnGetSuccess] = useState(false)

  const [showBvnResult, setShowBvnResult] = useState(false)

  const [bvnDetails, setBvnDetails] = useState(null)

  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useCookies(['token'])

  useEffect(() => {

    async function getEachCustomerBalancesById() {
      try {
        await eachCustomerDetails(id, token?.token).then((data) => {
          setDetails(data)
        }).catch((err) => {
          console.log(err)
        })
      } catch (err) {
        console.log(err.message)
      }

    }

    getEachCustomerBalancesById()
  }, [id])

  // call the bvn verification function
  async function checkBvm(bvnToCheck) {
    try {
      const data = JSON.stringify({
        bvn: bvnToCheck
      })

      const response = await axios.post(`${url}/admin/bvn-verification`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.token}`
        }
      })

      setBvnLoading(false)
      const receivedData = response.data

      bvnCardToggle()
      if (receivedData.success) {

        setBvnDetails(receivedData)

        setBvnGetSuccess(true)
      }


    } catch (error) {
      setBvnLoading(false)
      // console.log(error.response.data.message)
      bvnCardToggle()
    }
  }


  /**
   * The function `bvnCardToggle` toggles the visibility of a BVN result and sets a timeout to hide it
   * after a certain amount of time.
   */
  function bvnCardToggle() {
    setShowBvnResult(true)

    let time
    if (bvnGetSuccess) {
      time = 180000 // in milliseconds
    } else {
      time = 5000 // in milliseconds
    }

    setTimeout(() => {
      setShowBvnResult(false)
    }, time)
  }

  return (
    <div className="flex h-screen relative overflow-y-hidden">
      <Sidebar />

      <div className="flex-1 bg-gray-200 p-5 pt-6 overflow-y-auto">
        <Header title={`User's Verification`} />
        <div className="p-5 bg-[#ffffff] rounded-2xl w-full mt-10">
          <h2 className="mt-3 mb-2 font-semibold text-lg">User information</h2>

          {/**/}
          <p className="font-medium my-2 flex justify-between w-full max-w-[400px]">
            <span>Full Name:</span>{" "}
            <span className="text-gray-700 text-left md:basis-1/2">
              {details?.name}
            </span>
          </p>

          <p className="font-medium my-2 flex justify-between w-full max-w-[400px]">
            <span> Email:</span>{" "}
            <span className="text-gray-700 text-left md:basis-1/2">
              {details?.mail}
            </span>
          </p>

          <p className="font-medium my-2 flex justify-between w-full max-w-[400px]">
            <span>Card Type:</span>{" "}
            <span className="text-gray-700 text-left md:basis-1/2">NIN</span>
          </p>

          <p className="font-medium my-2 flex justify-between w-full max-w-[400px]">
            <span>NIN Number:</span>{" "}
            <span className="text-gray-700 text-left md:basis-1/2">
              {details?.nin}
            </span>
          </p>

          <p className="font-medium my-2 flex justify-between w-full max-w-[400px]">
            <span>Bank Verification Number {"(BVN)"}:</span>{" "}
            <span className="text-gray-700 text-left md:basis-1/2">
              {details?.bvn}
            </span>

            {details.bvn !== null ? <span className="">
              {!bvnLoading ? (
                <span onClick={() => {
                  setBvnLoading(true)
                  checkBvm(details.bvn)
                }} className="font-medium text-green-900 bg-green-100 hover:bg-green-300 transition-all duration-300 ease-in-out py-1 px-2 rounded cursor-pointer">Confirm
                </span>
              ) : (
                <span className="font-medium text-green-900 bg-green-100 hover:bg-green-300 transition-all duration-300 ease-in-out py-1 px-2 rounded cursor-pointer">
                  <CircularProgress size={18} color="inherit" />
                </span>)}
            </span> : null}

          </p>

          {/* Bvn Call Result */}
          {showBvnResult ? (
            <h2 className="mt-7 mb-2 font-semibold text-lg">
              BVN Verification Result
            </h2>
          ) : null}

          {showBvnResult ? (
            <div>
              {bvnGetSuccess ? (
                <div className="mb-5 mt-5">
                  <div className='w-[100px] h-[100px] backdrop border-[1px] border-300 rounded flex justify-center align-center'><img className="rounded w-full h-full" src={bvnDetails?.response?.data?.photo_id[0]?.url} alt='user-photoid' /></div>

                  <div><span className="text-gray-600 text-sm">First Name:</span> <span className="font-semibold">{bvnDetails?.response?.data?.firstname}</span></div>
                  <div><span className="text-gray-600 text-sm">Last Name:</span> <span className="font-semibold">{bvnDetails?.response?.data?.lastname}</span></div>
                  <div><span className="text-gray-600 text-sm">Address:</span> <span className="font-semibold">{bvnDetails?.response?.data?.address[0]}</span></div>
                  <div><span className="text-gray-600 text-sm">State of Origin:</span> <span className="font-semibold">{bvnDetails?.response?.data?.state_of_origin}</span></div>
                  <div><span className="text-gray-600 text-sm">State of Residence:</span> <span className="font-semibold">{bvnDetails?.response?.data?.state_of_residence}</span></div>
                  <div><span className="text-gray-600 text-sm">Date of Birth (DOB):</span> <span className="font-semibold">{bvnDetails?.response?.data?.dob}</span></div>
                  <div><span className="text-gray-600 text-sm">Gender (M/F):</span> <span className="font-semibold">{bvnDetails?.response?.data?.gender}</span></div>
                  <div><span className="text-gray-600 text-sm">Marital Status:</span> <span className="font-semibold">{bvnDetails?.response?.data?.marital_status}</span></div>
                  <div><span className="text-gray-600 text-sm">Email (<i>email attached with BVN</i>):</span> <span className="font-semibold">{bvnDetails?.response?.data?.email[0]}</span></div>
                  <div><span className="text-gray-600 text-sm">Phone Number:</span> <span className="font-semibold">{bvnDetails?.response?.data?.phone[0]}</span></div>

                  {/* action buttons */}
                  <div className="flex justify-center gap-3 my-5 flex-wrap mt-10">
                    {/* verify button */}
                    <p className="text-base font-medium text-green-900 bg-green-100 hover:bg-green-300 transition-all duration-300 ease-in-out w-full md:max-w-[200px] py-3 text-center rounded-xl cursor-pointer">
                      Verify BVN
                    </p>

                    {/* //   reject button */}
                    <p
                      onClick={() => setConfirmRejectBvnOpen(true)}
                      className="text-base font-medium text-red-900 bg-red-100 w-full md:max-w-[200px] py-3 text-center rounded-xl hover:bg-red-300 transition-all duration-300 ease-in-out cursor-pointer"
                    >
                      Reject BVN
                    </p>
                  </div>
                </div>
              ) : <div className="flex justify-center align-center">
                <Error color="error"></Error> <span className="font-semibold">Unable To Get BVN Result</span>
              </div>}
            </div>

          ) : null}






          {/* uploaded images */}
          <h2 className="mt-7 mb-2 font-semibold text-lg">
            Verification Images
          </h2>
          <div className="flex justify-center gap-3 flex-wrap mb-5">
            {/* // the nin image */}
            <div className="h-[300px] w-full md:max-w-[320px] md:mr-4 bg-[#f2f2f2] rounded-xl flex justify-center items-center">
              {/* // if there's an image */}
              {/* image 1 */}
              {/* <img
              className="object-contain max-h-[300px] max-w-[320px]"
              src="" alt="" /> */}

              {/* no image uploaded yet */}
              {details.ninPicture !== null ? <img className="object-contain max-h-[300px] max-w-[320px]" src={details.ninPicture} alt="nin-pics" /> : <div className="font-medium text-center">
                <HideImageRoundedIcon fontSize="large" />{" "}
                <p>No uploaded image</p>
              </div>}
            </div>

            {/* // the picture of the user holding a valid id card */}
            <div className="h-[300px] w-full md:max-w-[320px] md:mr-4 bg-[#f2f2f2] rounded-xl flex justify-center items-center">
              {/* // if there's an image */}
              {/* image 2 */}
              {/* <img className="object-contain max-h-[300px] max-w-[320px]" src="" alt="" /> */}

              {/* no image uploaded yet */}
              {details.selfie !== null ? <img className="object-contain max-h-[300px] max-w-[320px]" src={details.selfie} alt="user's-selfie" /> : <div className="font-medium text-center">
                <HideImageRoundedIcon fontSize="large" />{" "}
                <p>No uploaded image</p>
              </div>}
            </div>
          </div>

          {/* action buttons */}
          <div className="flex justify-center gap-3 my-5 flex-wrap mt-10">
            {/* verify button */}
            <p className="text-base font-medium text-green-900 bg-green-100 hover:bg-green-300 transition-all duration-300 ease-in-out w-full md:max-w-[200px] py-3 text-center rounded-xl">
              Verify
            </p>

            {/* //   reject button */}
            <p
              onClick={() => setConfirmRejectOpen(true)}
              className="text-base font-medium text-red-900 bg-red-100 w-full md:max-w-[200px] py-3 text-center rounded-xl hover:bg-red-300 transition-all duration-300 ease-in-out"
            >
              Reject
            </p>
          </div>
        </div>

        {/* the reject confirm message */}
        {confirmRejectOpen ? (
          <div className="bg-[#00000030] absolute top-0 left-0 bottom-0 right-0 flex justify-center items-center backdrop-blur-sm">
            <div className="bg-white rounded-2xl p-5 w-[90%] max-w-[600px] m-auto">
              <div className=" mb-5 text-right flex justify-between items-center">
                <h2 className="font-semibold text-xl">
                  Are you sure you want to decline?
                </h2>
                <ClearRoundedIcon onClick={() => setConfirmRejectOpen(false)} />
              </div>

              <label className="font-medium text-lg">
                Reason for rejection
              </label>
              <textarea
                name="details"
                id="details"
                cols="30"
                rows="10"
                className="rounded-xl bg-[#f0f0f0] font-medium w-full h-24 text-sm p-2 focus:outline-none focus:ring-gray-900 "
              ></textarea>

              {/* //   reject button */}
              <p className="mt-7 mx-auto text-base font-semibold text-red-900 bg-red-100 w-full md:max-w-[200px] py-3 text-center rounded-xl hover:bg-red-300 transition-all duration-300 ease-in-out">
                Confirm Rejection
              </p>
            </div>
          </div>
        ) : null}

        {confirmRejectBvnOpen ? (
          <div className="bg-[#00000030] absolute top-0 left-0 bottom-0 right-0 flex justify-center items-center backdrop-blur-sm">
            <div className="bg-white rounded-2xl p-5 w-[90%] max-w-[600px] m-auto">
              <div className=" mb-5 text-right flex justify-between items-center">
                <h2 className="font-semibold text-xl">
                  Are you sure you want to reject the BVN?
                </h2>
                <ClearRoundedIcon onClick={() => setConfirmRejectBvnOpen(false)} />
              </div>

              <label className="font-medium text-lg">
                Reason for rejection
              </label>
              <textarea
                name="details"
                id="details"
                cols="30"
                rows="10"
                className="rounded-xl bg-[#f0f0f0] font-medium w-full h-24 text-sm p-2 focus:outline-none focus:ring-gray-900 "
              ></textarea>

              {/* //   reject button */}
              <p className="mt-7 mx-auto text-base font-semibold text-red-900 bg-red-100 w-full md:max-w-[200px] py-3 text-center rounded-xl hover:bg-red-300 transition-all duration-300 ease-in-out">
                Confirm BVN Rejection
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UserVerification;
