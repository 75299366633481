/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../component/ui/SideBar";
import Header from "../../component/ui/Header";
import { useNavigate, useParams } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import VerifiedUserRoundedIcon from "@mui/icons-material/VerifiedUserRounded";
import GppBadRoundedIcon from "@mui/icons-material/GppBadRounded";
import eachCustomerDetails from "../../component/api/eachCustomerDetails";
import currencyFormatter from "currency-formatter";
import { useCookies } from "react-cookie";
import { AppContext } from "../../AppContext";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { url } from "../../component/url";
import axios from "axios";
import UserHistory from "../../component/ui/UserHistory";

const CustomerDetails = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [details, setDetails] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useCookies(["token"]);
  const { setShowLoading } = useContext(AppContext);

  useEffect(() => {
    async function getEachCustomerBalancesById() {
      try {
        setShowLoading(true);
        await eachCustomerDetails(id, token?.token)
          .then((data) => {
            setDetails(data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err.message);
      } finally {
        setShowLoading(false);
      }
    }

    getEachCustomerBalancesById();
  }, []);

  const handleSuspendAccount = async () => {
    try {
      setShowLoading(true);

      const res = await axios.post(
        `${url}/admin/suspend-user`,
        {
          email: details?.mail,
          suspended: details?.suspended ? "false" : "true",
        },
        {
          headers: {
            Authorization: `Bearer ${token?.token}`,
          },
        }
      );

      alert(res.data?.message);
      window.location.reload();
    } catch (error) {
      alert(error.message);
    } finally {
      setShowLoading(false);
    }
  };

  return (
    <div className="flex h-screen relative overflow-y-hidden">
      <Sidebar />
      <div className="flex-1 bg-gray-200 p-5 pt-6 overflow-y-auto">
        <Header title={`Customer's details`} />

        {details !== "" && details?.success === true && (
          <div className="mt-10">
            <div className="md:flex gap-5 items-center flex-wrap">
              {/*customer image */}
              <div
                className={`mx-auto md:mx-0 h-[100px] w-[100px] rounded-full border-2 border-[#F0f0f0] bg-white flex justify-center items-center p-1  ring-[#00000070] ring-2`}
              >
                {details.image !== null ? (
                  <img
                    src={details?.image}
                    alt="user-img"
                    className="h-[100px] w-[100px] rounded-full object-cover"
                  />
                ) : (
                  <PersonIcon fontSize="large" />
                )}
              </div>

              <div className="text-center md:text-left">
                <h1 className="text-2xl text-[#1f1f29] font-bold">
                  Account name: {" " + details?.name}{" "}
                  <span className="text-base font-medium text-gray-500 ml-10">
                    {details?.verified ? "Verified" : "Not verified"}
                  </span>
                  {details?.verified ? (
                    <VerifiedUserRoundedIcon
                      sx={{ fontSize: "20px" }}
                      color="success"
                    />
                  ) : (
                    <GppBadRoundedIcon
                      sx={{ fontSize: "22px" }}
                      color="error"
                    />
                  )}
                </h1>
                <p className="text-gray-700 text-lg font-medium">
                  Username: {" " + details?.username}
                </p>
                <p className="text-gray-700 text-lg font-medium">
                  Phone Number:
                  {" " + details?.phoneNumber ? details?.phoneNumber : "--"}
                </p>
                <p className="text-gray-700 text-lg font-medium">
                  Mail: {" " + details?.mail}
                </p>
                <p className="text-gray-700 text-lg font-medium">
                  Sex: {" " + details?.gender}
                </p>
                <p className="text-gray-700 text-lg font-medium">
                  Suspended: {details?.suspended ? "Yes" : "No"}
                </p>
                <p className="text-gray-500 text-sm font-medium">
                  Account created on: {" " + details?.signupDate}
                </p>
                <p className="text-gray-500 text-sm font-medium">
                  Account Name: {" " + details?.accountName}
                </p>
                <p className="text-gray-500 text-sm font-medium">
                  Account Number: {" " + details?.accountNumber}
                </p>
              </div>
            </div>

            {/* customers account balance */}
            <h1 className="text-xl font-semibold mt-10 mb-3">
              Wallet balances
            </h1>

            <div className="flex justify-between flex-wrap gap-y-4">
              <div className="p-5 rounded-xl rounded-br-none w-full md:w-[43%] lg:w-[19%] bg-[#40009520]">
                <p className="font-semibold text-base">Penny</p>
                <h1 className="font-bold text-2xl mt-3">
                  {currencyFormatter.format(details.pennyBalance, {
                    code: "NGN",
                  })}
                </h1>
              </div>

              <div className="p-5 rounded-xl rounded-br-none w-full md:w-[43%] lg:w-[19%] bg-[#00000020]">
                <p className="font-semibold text-base">Safe Box</p>
                <h1 className="font-bold text-2xl mt-3">
                  {" "}
                  {currencyFormatter.format(details.safeboxBalance, {
                    code: "NGN",
                  })}
                </h1>
              </div>

              <div className="p-5 rounded-xl rounded-br-none w-full md:w-[43%] lg:w-[19%] bg-[#EA532220]">
                <p className="font-semibold text-base">Treasure</p>
                <h1 className="font-bold text-2xl mt-3">
                  {" "}
                  {currencyFormatter.format(details.treasureBalance, {
                    code: "NGN",
                  })}
                </h1>
              </div>
              <div className="p-5 rounded-xl rounded-br-none w-full md:w-[43%] lg:w-[19%] bg-[#6E578620]">
                <p className="font-semibold text-base">Stash</p>
                <h1 className="font-bold text-2xl mt-3">
                  {" "}
                  ₦{details?.stashBalance}
                </h1>
              </div>
              <div className="p-5 rounded-xl rounded-br-none w-full md:w-[43%] lg:w-[19%] bg-[#6E578640]">
                <p className="font-semibold text-base">Lock</p>
                <h1 className="font-bold text-2xl mt-3">
                  {" "}
                  ₦{details.lockBalance}
                </h1>
              </div>
            </div>

            {/* transaction history */}
            <UserHistory id={id} />

            {/*customers account management */}
            <div className="mt-20">
              <h1 className="text-xl font-semibold mb-2">Account Management</h1>

              {!details.verified && (
                <h2
                  onClick={() => navigate(`/verification/${id}`)}
                  className="cursor-pointer bg-white border-white hover:border-black border-2 transition-all duration-300 ease-in-out rounded-xl mx-1 w-full md:w-[200px] text-center py-3 px-4 font-semibold text-base inline-block my-2"
                >
                  Verify Account
                </h2>
              )}

              <h2
                onClick={() => {
                  const res = prompt(
                    `Are you sure you want to ${
                      details?.suspended ? "unsuspend" : "suspend"
                    } ${details?.name?.toUpperCase()}? type yes to confirm.`
                  );

                  if (res?.toLowerCase() === "yes") {
                    handleSuspendAccount();
                  }
                }}
                className="cursor-pointer bg-red-100 hover:bg-red-600 hover:text-white transition-all duration-300 ease-in-out text-red-600 rounded-xl w-full md:w-[200px] text-center py-4 px-5 font-semibold text-base inline-block my-2 mx-1"
              >
                {details?.suspended ? "Unsuspend Account" : " Suspend User"}
              </h2>

              <h2 className="bg-gray-300 rounded-xl w-full hover:bg-black hover:text-white transition-all duration-300 ease-in-out md:w-[200px] text-center py-4 px-5 font-semibold text-base inline-block my-2 mx-1">
                Add to blaclist
              </h2>
            </div>
          </div>
        )}

        {details?.success === false && (
          <div className="flex flex-1 items-center justify-center h-full">
            <div className="flex-col flex items-center">
              <ReportGmailerrorredIcon
                sx={{
                  fontSize: "150px",
                }}
                className="text-red-400 text-center "
              />
              <p className="font-bold text-gray text-2xl text-center">Error</p>
              <p className="font-semibold text-gray text-base text-center max-w-[300px]">
                There's an error in fetching user's details
                {", " + details?.message}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerDetails;
