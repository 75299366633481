// Sidebar.js
import React from "react";
import { Link, useLocation } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { thickPurple } from "./metrics";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

function Sidebar() {
  const { pathname } = useLocation();
  const [open, setOpen] = React.useState(false);

  const navList = [
    {
      path: "/dashboard",
      title: "Dashboard",
      icon: <DashboardCustomizeOutlinedIcon color="inherit" />,
    },
    {
      path: "/customers/1000",
      title: "Users",
      icon: <GroupOutlinedIcon color="inherit" />,
    },
    {
      path: "/transaction",
      title: "Transactions",
      icon: <ReceiptOutlinedIcon color="inherit" />,
    },
    {
      path: "/documentation",
      title: "Documentation",
      icon: <DocumentScannerOutlinedIcon color="inherit" />,
    },
    {
      path: "/logout",
      title: "Logout",
      icon: <ExitToAppOutlinedIcon color="inherit" />,
    },
  ];

  // console.log(pathname);
  return (
    <div
      className={`bg-[#220d3d] text-white z-[999] h-screen absolute -left-${
        open ? 0 : 20
      }  md:relative md:w-[${
        open ? "255px" : "85px"
      }]  p-4 pt-6  md:left-0 md:block  transition-all ease-in-out duration-300`}
    >
      {/* logo */}
      <div className="flex justify-between items-center">
        {open && (
          <img
            src="https://jesekung.com/images/logowhite.png"
            alt="jeseku-logo"
            className={`h-8 ml-5 md:${open ? "block" : "hidden"}`}
          />
        )}

        <div className="p-4  rounded-2xl">
          <MenuOutlinedIcon
            onClick={() => setOpen(!open)}
            className="text-white text-3xl"
          />
        </div>
      </div>

      <ul className="my-7">
        {navList.map((item, index) => {
          return (
            <Link
              title={`Go to ${item.title} page`}
              to={item.path}
              key={index}
              className={`sidebar-nav-link bg-[${
                pathname === item.path && "#ffffff"
              }] text-[${
                pathname === item.path && "#220d3d"
              }] my-3 font-semibold px-4 py-4 rounded-lg flex  hover:bg-[#ffffff80] hover:text-[#220d3d] transition-all duration-300 ease-in-out cursor-pointer`}
              style={{
                background: pathname === item.path && "white",
                color: pathname === item.path && thickPurple,
              }}
            >
              {item?.icon}
              <p
                style={{ display: open ? "block" : "none" }}
                className="ml-2 flex-1"
              >
                {item.title}
              </p>
            </Link>
          );
        })}
      </ul>
    </div>
  );
}

export default Sidebar;
