import React, { useState } from "react";
import logo from "../../assets/images/jeseku-white-logo-icon.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { useCookies } from "react-cookie";
import { url } from "../../component/url";

const SignInPage = () => {
  // STATE TO STORE THE LOGIN DETAILS
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookies, removeCookies] = useCookies(["token"]);
  const navigate = useNavigate();

  /**
   * The function `processSignIn` is an asynchronous function that handles the sign-in process by
   * sending a POST request to a specified URL with the username and password, and then redirects to the
   * dashboard page if the login is successful.
   */
  async function processSignIn() {
    setIsLoading(true);
    try {
      const data = JSON.stringify({
        username,
        password,
      });

      const response = await axios.post(`${url}/admin/login`, data, {
        headers: { "Content-Type": "application/json" },
      });

      const receivedData = response;

      if (!receivedData.success) {
        console.error(receivedData.message);
      }
      setCookies("token", receivedData.data.token, { path: "/" });
      navigate("/dashboard");
    } catch (error) {
      if (error.response) {
        alert(error.response.data.message, "error in request");
        console.log(error.response.data.message);
      } else if (error.request) {
        alert(error.request, "error in request");
        console.log(error.request);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="sign-in-page flex flex-1 min-h-screen">
      <div className="bg-[#410095d4] flex flex-col flex-1 backdrop-blur-sm justify-center items-center">
        <div className="flex items-center mb-6">
          <img src={logo} alt="jeseku-white-logo" className="h-[65px]" />
          <span className="text-white font-semibold text-3xl tracking-wide ml-2">
            Jesékù
          </span>
        </div>

        <form
          action=""
          className="p-5 pt-8 rounded-[30px] bg-white w-[85%] max-w-[400px] h-fit"
        >
          <h1 className="font-semibold text-center text-3xl text-[#1f1f29]">
            Welcome Back!
          </h1>
          <p className="font-medium text-center text-base text-[#1f1f29]">
            Sign in to your account to continue
          </p>

          {/* EMAIL OR USERNAME */}
          <label
            className="font-medium text-[#a1a1a1] mb-1 block mt-8"
            htmlForr="username"
            name="username"
          >
            Email/Username
          </label>
          <input
            className="py-4 px-3 bg-[#f0f0f0] rounded-[16px] w-full block text-base focus:outline-0 focus:ring-2 focus: ring-[#41009594] border-white border-2 font-medium text-gray"
            value={username}
            onChange={(e) => setUserName(e.target.value)}
            type="text"
            name="email"
            required
          />

          {/* password */}
          <label
            className="font-medium text-[#a1a1a1] mb-1 mt-5 block"
            htmlFor="password"
            name="password"
          >
            Password
          </label>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="text-gray py-3 px-3 bg-[#ececec] rounded-[16px] w-full block text-2xl focus:outline-0 focus:ring-2 focus: ring-[#41009594] border-white border-2 font-medium"
            type="password"
            name="password"
            id="password"
          />

          {/* the submit button */}
          {/* just ad an onclick function to process the sign in */}
          <div
            onClick={() =>
              // username === "" || password === "" ? null : navigate("/dashboard")
              processSignIn()
            }
            style={{ opacity: username === "" || password === "" ? 0.6 : 1 }}
            className="cursor-pointer text-center rounded-[16px] font-semibold py-4 text-base my-3 transition-all 300ms ease-in-out bg-[#400095] text-white mt-8"
          >
            {!isLoading ? (
              "Sign In"
            ) : (
              <CircularProgress size={18} color="inherit" />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignInPage;
