import React, { useState } from "react";
import { useGetData } from "../../hooks/useGetData";
import useGetToken from "../../hooks/useGetToken";
import pennyIcon from "../../assets/images/penny-icon.png";
import safeboxIcon from "../../assets/images/safebox-icon.png";
import treasureIcon from "../../assets/images/treasure-icon.png";
import stashIcon from "../../assets/images/stash-icon.png";
import { useNavigate } from "react-router-dom";

const UserHistory = ({ id }) => {
  const [data, setData] = useState([]);
  const { token } = useGetToken("token");
  const navigate = useNavigate();

  const { fetchData } = useGetData(
    token,
    `/admin/get-user-transaction-history/${id}`,
    setData
  );

  return (
    <div
      style={{
        display: data && data.details ? "block" : "none",
      }}
      className="mt-5 pt-4 hidden"
    >
      <div className="relative flex flex-col items-center rounded-[10px] border-[1px] border-gray-200 w-full max-w-[576px] p-4 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800  dark:shadow-none">
        <div className="flex items-center justify-between rounded-t-3xl p-3 w-full">
          <div className="text-lg font-bold text-navy-700 ">
            Recent Transactions
          </div>
          <button
            onClick={() => navigate(`/transactions/${id}`)}
            style={{ color: "purple" }}
            className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5  dark:hover:bg-white/10 dark:active:bg-white/20"
          >
            See all
          </button>
        </div>

        {data &&
          data?.details?.map((item, index) => (
            <div
              style={{ cursor: "pointer", display: index > 2 && "none" }}
              key={index}
              className="flex h-full w-full items-start justify-between rounded-md border-[1px] border-[transparent]  bg-white px-3 py-[20px] transition-all duration-150 hover:border-gray-200 "
            >
              <div className="flex items-center gap-3">
                <div className="flex h-16 w-16 items-center justify-center bg-slate-200 rounded-lg">
                  <img
                    className="w-7 rounded-xl"
                    src={
                      item.type === "penny"
                        ? pennyIcon
                        : item.type === "safebox"
                        ? safeboxIcon
                        : item.type === "treasure"
                        ? treasureIcon
                        : stashIcon
                    }
                    alt={`${item.type}-icon`}
                  />
                </div>
                <div className="flex flex-col">
                  <h5 className="text-base font-bold text-navy-700 ">
                    {item.type}
                  </h5>
                  <p className="mt-1 text-sm font-normal text-gray-600">
                    {item.credit ? item.from : item.to}
                  </p>
                </div>
              </div>

              <div className="mt-1 text-right justify-center text-navy-700 ">
                <div
                  style={{ color: !item.credit ? "red" : "green" }}
                  className="ml-1 text-sm font-bold text-navy-700 "
                >
                  {item.amount}
                </div>

                <div className="ml-2 mt-2  text-sm font-normal text-gray-600 ">
                  <p>{item.date}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default UserHistory;
