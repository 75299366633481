import React, { useState } from "react";
import useGetToken from "../../hooks/useGetToken";
import { useGetData } from "../../hooks/useGetData";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import treasureIcon from "../../assets/images/treasure-icon.png";
import safeboxIcon from "../../assets/images/safebox-icon.png";
import { useNavigate } from "react-router-dom";

const AllSavers = () => {
  const [data, setData] = useState([]);
  const [sortData, setSortData] = useState("All");
  const { token } = useGetToken("token");
  const navigate = useNavigate();

  const { fetchData } = useGetData(
    token,
    `/admin/get-users-with-savings`,
    setData
  );
  const sortedData =
    data && data?.users?.sort((a, b) => b.total_amount - a.total_amount);

  console.log(data);

  return (
    <div className="p-4">
      <div className="flex items-center py-5">
        <ArrowBackOutlinedIcon
          className="cursor-pointer text-black"
          onClick={() => window.history.back()}
        />
        <h1 className="font-bold text-2xl ml-3 flex-1">Savings</h1>

        {/* search and filter */}
        <div className=" flex items-center">
          <input
            placeholder="Search by Id, Username"
            type="search"
            className="p-3 rounded focus:ring-2 focus:ring-gray-500 bg-gray-200 mx-2 focus:outline-none"
          />

          <div>
            <select className="p-3 border-[2px] border-gray-500 rounded font-medium">
              <option>All</option>
              <option>Treasure</option>
              <option>Safebox</option>
            </select>
          </div>
        </div>
      </div>

      {data && (
        <div class="flex flex-col mt-5 relative">
          <div class="overflow-x-auto">
            <div class=" inline-block min-w-full">
              <div class="overflow-hidden">
                <table class="min-w-full relative ">
                  <thead class="bg-gray-200 border-b sticky top-0">
                    <tr>
                      <th
                        scope="col"
                        class="text-base font-semibold text-gray-900 px-6 py-4 text-left"
                      >
                        #ID
                      </th>
                      <th
                        scope="col"
                        class="text-base font-semibold text-gray-900 px-6 py-4 text-left"
                      >
                        Username
                      </th>
                      <th
                        scope="col"
                        class="text-base font-semibold text-gray-900 px-6 py-4 text-left"
                      >
                        Plan
                      </th>
                      <th
                        scope="col"
                        class="text-base font-semibold text-gray-900 px-6 py-4 text-left"
                      >
                        Amount
                      </th>
                      <th
                        scope="col"
                        class="text-base font-semibold text-gray-900 px-6 py-4 text-left"
                      >
                        Start Date
                      </th>
                      <th
                        scope="col"
                        class="text-base font-semibold text-gray-900 px-6 py-4 text-left"
                      >
                        End Date
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {data &&
                      sortedData?.map((user, index) => {
                        return (
                          <tr
                            onClick={() => navigate(`/details/${user.id}`)}
                            key={index}
                            class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                          >
                            <td class="px-6 py-4 text-base whitespace-nowrap font-semibold text-gray-900">
                              {user.id}
                            </td>
                            <td class="text-base text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                              {user.username}
                            </td>
                            <td
                              class={`text-plan ${
                                user.plan === "treasure"
                                  ? "text-orange-400"
                                  : "text-gray-900"
                              } font-medium px-6 py-3 text-lg whitespace-nowrap flex items-center rounded-sm  ${
                                user.plan === "treasure"
                                  ? "bg-orange-50"
                                  : "bg-gray-100"
                              } my-2`}
                            >
                              <img
                                className="h-6 w-6 mr-3"
                                alt="plan-logo"
                                src={
                                  user.plan === "treasure"
                                    ? treasureIcon
                                    : safeboxIcon
                                }
                              />
                              {user.plan}
                            </td>
                            <td class="text-lg font-semibold text-green-950 px-6 py-4 whitespace-nowrap">
                              ₦{user.total_amount.toFixed(2).toLocaleString()}
                            </td>
                            <td class="text-base text-gray-600 font-medium px-6 py-4 whitespace-nowrap">
                              {new Date(user?.first_deposit).toDateString()}
                              {` | `}
                              {new Date(
                                user?.first_deposit
                              ).toLocaleTimeString()}
                            </td>
                            <td class="text-base text-gray-600 font-medium px-6 py-4 whitespace-nowrap">
                              {new Date(user?.withdrawal_date).toDateString()}
                              {` | `}
                              {new Date(
                                user?.withdrawal_date
                              ).toLocaleTimeString()}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllSavers;
