import "./App.css";
// import { createContext } from "react";
import DashboardPage from "./pages/DashboardPage";
import SignInPage from "./pages/auth/SignInPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Customers from "./pages/customer/Customers";
import CustomerDetails from "./pages/customer/CustomerDetails";
import UserVerification from "./pages/UserVerification";
import { AppProvider } from "./AppContext";
import Documentation from "./pages/Documentation";
import ProtectedRoutes from "./component/functions/ProtectedRoutes";
import Logout from "./component/functions/Logout";
import TransactionPage from "./pages/transaction/TransactionPage";
import Loading from "../src/component/ui/loadingPopUp";
import TransactionHistory from "./pages/transaction/TransactionHistory";
import AllSavers from "./pages/savings/AllSavers";

function App() {
  return (
    // <AppContext.Provider>
    <div>
      <AppProvider>
        <BrowserRouter>
          <Routes>
            <Route element={<ProtectedRoutes />}>
              <Route path="/dashboard" Component={DashboardPage} />
              <Route path="/customers/" Component={Customers} />
              <Route path="/customers/:limit" Component={Customers} />
              <Route path="/details/:id" Component={CustomerDetails} />
              <Route path="/verification/:id" Component={UserVerification} />
              <Route path="/transaction" Component={TransactionPage} />
              <Route path="/transactions/:id" Component={TransactionHistory} />
              <Route path="/savings" Component={AllSavers} />
            </Route>
            <Route path="/documentation" Component={Documentation} />
            <Route path="/" Component={SignInPage} />
            <Route path="/logout" Component={Logout} />
            <Route path="*" Component={SignInPage} />
          </Routes>
          <Loading />
        </BrowserRouter>
      </AppProvider>

      {/* <Loading /> */}
    </div>

    // </AppContext.Provider>
  );
}

export default App;
