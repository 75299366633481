import React, { useState } from "react";
import Header from "../../component/ui/Header";
import Sidebar from "../../component/ui/SideBar";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import HistoryIcon from "@mui/icons-material/History";
import ArrowBackIosTwoToneIcon from "@mui/icons-material/ArrowBackIosTwoTone";
import useGetToken from "../../hooks/useGetToken";
import ModifyUserBalance from "../../component/functions/modifyUserBalance";
import { AppContext } from "../../AppContext";
import { useGetData } from "../../hooks/useGetData";
import SingleHistory from "../../component/ui/SingleHistory";

const TransactionPage = () => {
  const [operation, setOperation] = useState(null);
  const [accountFound, setAccountFound] = useState(null);
  const [data, setData] = useState([]);
  const [form, setForm] = useState({
    accNo: "",
    amount: "",
    pin: "",
    balanceType: "penny",
  });
  const [accountName, setAccountName] = useState("");
  const { token } = useGetToken("token");
  const { modifyBalance } = ModifyUserBalance(
    token,
    form.accNo,
    operation,
    form.amount,
    form.pin,
    form.balanceType
  );
  const { fetchData } = useGetData(
    token,
    "/admin/transaction-history",
    setData
  );

  const OperationBtn = ({ title, onClick, icon, style }) => {
    return (
      <div
        title={`Button to ${title}`}
        onClick={onClick}
        className={`cursor-pointer hover:bg-gray-300 p-5 rounded-md w-[45%] max-w-[300px] ${style}`}
      >
        {icon}
        <p className="text-2xl font-semibold text-gray-600 mt-10 whitespace-pre-line overflow-hidden overflow-ellipsis">
          {title}
        </p>
      </div>
    );
  };

  const handlePrompt = (e) => {
    const userInput = window.prompt(
      `Are you sure you want to ${operation} ${accountName} with ${form.amount} ? Type "yes" to confirm.`
    );

    // Check user input
    if (userInput && userInput.toLowerCase() === "yes") {
      modifyBalance();
    } else {
      alert("Transaction cancelled");
    }
  };

  return (
    <div className="flex h-screen relative overflow-y-hidden">
      <Sidebar />

      <div className="flex-1 bg-gray-200 p-4 md:p-10 pt-6 overflow-y-auto">
        {/* Content of the dashboard */}
        <Header title={"Transaction"} />

        <p className="mt-4 text-gray-600 text-base font-medium max-w-[500px] mb-5">
          Complete transactions seamlessly by securely crediting or debiting
          users using their account numbers. Verify recipient details for added
          peace of mind, ensuring accurate and efficient transfers.
        </p>

        <div className="flex flex-wrap gap-5 mt-4">
          {/* Content of the dashboard */}
          {[
            {
              title: "Credit user",
              onclick: () => setOperation("deposit"),
              icon: (
                <AddBoxIcon
                  sx={{ fontSize: "50px" }}
                  className="text-[140px] text-green-500"
                />
              ),
              style: "bg-green-100",
            },
            {
              title: "Debit user",
              onclick: () => setOperation("withdraw"),
              icon: (
                <IndeterminateCheckBoxIcon
                  sx={{ fontSize: "50px" }}
                  className=" text-red-500"
                />
              ),
              style: "bg-red-100",
            },
          ].map((item, index) => {
            return (
              <OperationBtn
                key={index}
                icon={item.icon}
                title={item.title}
                style={item.style}
                onClick={item.onclick}
              />
            );
          })}
        </div>

        {/* transaction history */}
        <div className="bg-white p-5 rounded-2xl my-10">
          <p className="text-2xl font-semibold text-gray mb-5">
            Transaction History
          </p>

          {data?.data?.map((item, index) => (
            <SingleHistory data={item} key={index} />
          ))}
        </div>
      </div>

      {/* the transaction operation pop up */}
      {operation !== null && (
        <div className="absolute w-[100dvw] bg-[#00000080] h-[100dvh]">
          <div className="bg-[#ffffff70] backdrop-blur-md h-[80%] rounded-lg w-[80%] max-w-[400px] mx-auto md:ml-[auto] my-auto mt-[10dvh] lg:mr-[5%]">
            {/* header */}
            <div className="p-5 border-b-[2px] border-gray-600 flex items-center">
              <ArrowBackIosTwoToneIcon
                fontSize={"small"}
                className="text-gray"
                onClick={() => {
                  setOperation(null);
                  setForm({ accNo: "", amount: "", pin: "" });
                }}
              />
              <p className="pl-5 font-semibold text-gray text-xl ">
                {operation === "deposit" ? "Credit user" : "Debit user"}
              </p>
            </div>

            <form action="" className="p-5">
              <p className="text-gray-900 text-base mb-5 font-medium">
                Input user's account details, verify before making transaction
              </p>

              <input
                type="text"
                placeholder="User's Account Number"
                // name="accountNumber"
                maxLength={10}
                minLength={10}
                value={form.accNo}
                onChange={(e) => setForm({ ...form, accNo: e.target.value })}
                className="block w-full  p-4 text-gray text-base rounded-lg placeholder:text-sm focus:outline-offset-2 outline-gray-700"
                autoComplete="false"
              />

              {/* Amount of the transaction */}
              <input
                type="number"
                placeholder="Amount"
                required
                // name="amount"
                value={form.amount}
                onChange={(e) => setForm({ ...form, amount: e.target.value })}
                className="mt-3 block w-full  p-4 text-gray text-base rounded-lg placeholder:text-sm focus:outline-offset-2 outline-gray-700"
                autoComplete="false"
              />

              {/* plan */}
              <select
                onChange={(e) => {
                  setForm({ ...form, balanceType: e.target.value });
                }}
                className="mt-3 block w-full  p-4 text-gray text-base rounded-lg placeholder:text-sm focus:outline-offset-2 outline-gray-700"
                required
              >
                <option value="penny">Penny</option>
                <option value="safebox">Safebox</option>
                <option value="treasure">Treasure</option>
              </select>

              {/* pin */}
              <input
                // type="password"
                placeholder="Transaction pin"
                value={form.pin}
                onChange={(e) => setForm({ ...form, pin: e.target.value })}
                maxLength={10}
                minLength={3}
                className="mt-3 block w-full  p-4 text-gray text-base rounded-lg placeholder:text-sm focus:outline-offset-2 outline-gray-700"
                autoComplete="false"
              />

              {/* account found or not */}
              {accountFound !== null && accountFound !== undefined && (
                <p
                  className={`
            ${
              accountFound
                ? "text-green-600 bg-green-100"
                : "text-red-600 bg-red-200"
            } mt-2 text-sm font-medium text-center p-1 rounded-full`}
                >
                  {accountFound ? "Account found" : "Account not found"}:
                </p>
              )}

              <p
                onClick={(e) => {
                  // eslint-disable-next-line no-unused-expressions
                  form.accNo !== "" &&
                  form?.accNo.length === 10 &&
                  form.pin !== "" &&
                  form.amount !== ""
                    ? handlePrompt(e)
                    : alert(
                        "Ensure that all fields are filled and filled correctly"
                      );
                }}
                className="hover:bg-gray-800 bg-gray-900 cursor-pointer text-white mt-20 rounded-lg p-4 text-base font-semibold text-center w-full "
              >
                Proceed
              </p>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransactionPage;
