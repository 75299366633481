import React from "react";
import { thickPurple } from "./metrics";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";

const Header = ({ title }) => {
  return (
    <div className="flex justify-between items-center">
      <h1 className={`text-3xl font-semibold text-[${thickPurple}]`}>
        {title}
      </h1>

      <div className="flex justify-between items-center ml-2">
        {/* search button */}
        <div className="bg-white py-3 px-4 rounded-2xl hidden md:inline-block">
          <SearchIcon />
          <input
            type="search"
            placeholder="Type to search"
            name="search"
            id=""
            className="focus:outline-0"
          />
        </div>

        {/* notification icon */}
        <div className="bg-white rounded-2xl mx-2 p-3">
          <NotificationsIcon sx={{ color: thickPurple, fontSize: "25px" }} />
        </div>

        {/* avatar */}
        <div className="bg-white rounded-2xl p-3">
          <PersonIcon sx={{ color: thickPurple, fontSize: "25px" }} />
        </div>
      </div>
    </div>
  );
};

export default Header;
