import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";

const useGetToken = (cookieName) => {
  const [cookies, setCookie] = useCookies([cookieName]);
  const [token, setToken] = useState(cookies[cookieName] || "");

  useEffect(() => {
    setToken(cookies[cookieName] || "");
  }, [cookies, cookieName]);

  const saveTokenToCookie = (newToken) => {
    setToken(newToken);
    setCookie(cookieName, newToken, { path: "/" });
  };

  const removeTokenFromCookie = () => {
    setToken("");
    setCookie(cookieName, "", { path: "/", maxAge: 0 });
  };

  return { token, saveTokenToCookie, removeTokenFromCookie };
};

export default useGetToken;
