import axios from "axios";
import { url } from "../url";

export default async function eachCustomerDetails(id, token) {
  try {
    const response = await axios.get(
      `${url}/admin/each-customer-details/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const receivedData = response.data;

    if (receivedData.success) {
      return receivedData;
    } else {
      return { success: false, message: "Could not get data" };
    }
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  }
}
