import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";
// import { Chart } from "chart.js";

const PieChart = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const myChartRef = chartRef.current.getContext("2d");

    chartInstance.current = new Chart(myChartRef, {
      type: "pie",
      data: {
        labels: ["Penny", "Safe Box", "Trasure", "Stash", "Lock"],
        datasets: [
          {
            label: "plan usage percentage",
            data: [30, 30, 40, 20, 40],
            backgroundColor: [
              "#40009570",
              "#00000070",
              "#EA532270",
              "#6E578620",
              "#6E578670",
            ],
          },
        ],
      },
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  return (
    <div>
      <canvas
        ref={chartRef}
        className="md:max-h-[400px] w-full md:max-w-[450px] mx-auto"
      />
    </div>
  );
};

export default PieChart;
