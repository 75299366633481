/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Sidebar from "../component/ui/SideBar";
import Header from "../component/ui/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import { initialStateWallet } from "../component/functions/details";
import KeyboardDoubleArrowDownRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowDownRounded";
import KeyboardDoubleArrowUpRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowUpRounded";
import "swiper/css";
import PieChart from "../component/ui/pieChart";
import getAppDetails from "../component/api/AppDetails";
import currencyFormatter from "currency-formatter";
import { useCookies } from "react-cookie";
import TopSavers from "./savings/TopSavers";
import { useNavigate } from "react-router-dom";

const DashboardPage = () => {
  const [Wallet, setWallet] = useState(initialStateWallet);
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useCookies(["token"]);
  const navigate = useNavigate();

  useEffect(() => {
    // get wallet's data
    /**
     * The function fetchWallet retrieves wallet data and updates the balances of different wallet
     * items based on the response.
     */
    async function fetchWallet() {
      await getAppDetails(token?.token)
        .then((data) => {
          if (data.success) {
            setWallet((prevWallet) => {
              return prevWallet.map((walletItem) => {
                // Map the wallet item to the corresponding balance in the response
                switch (walletItem.title) {
                  case "Penny":
                    return {
                      ...walletItem,
                      balance: data.message.wallet.penny_balance,
                    };
                  case "Safe Box":
                    return {
                      ...walletItem,
                      balance: data.message.wallet.safebox_balance,
                    };
                  case "Treasure":
                    return {
                      ...walletItem,
                      balance: data.message.wallet.treasure_balance,
                    };
                  case "Stash":
                    return {
                      ...walletItem,
                      balance: data.message.wallet.stash_balance,
                    };
                  case "Lock":
                    return {
                      ...walletItem,
                      balance: data.message.wallet.lock_balance,
                    };
                  case "Total balance":
                    return {
                      ...walletItem,
                      balance: data.message.wallet.total_balance,
                    };
                  default:
                    return walletItem; // No update needed for other items
                }
              });
            });
          } else {
            console.log(data.message);
          }
        })
        .catch((err) => {
          console.error(err.message);
        });
    }

    fetchWallet();
  }, []);

  return (
    <div className="flex h-screen relative overflow-y-hidden">
      <Sidebar />

      <div className="flex-1 bg-gray-200 p-4 md:p-10 pt-6 overflow-y-auto">
        {/* Content of the dashboard */}
        <Header title={"Dashboard"} />

        {/* the savings wallet */}
        <div className="mt-4 relative w-full md:max-w-4/5">
          <h2 className="font-medium text-base text-[#1f1f29] my-0">
            Wallet balances
          </h2>
          {/* md:max-w-[calc(80vw-80px)] */}
          <div className="relative max-w-[100%]  mt-1">
            <Swiper
              spaceBetween={20}
              slidesPerView={"auto"}
              className="max-w-[100%]"
            >
              {Wallet.map((item, index) => {
                return (
                  <SwiperSlide
                    style={{ background: item.bg }}
                    className=" rounded-2xl bg-white p-5 w-full max-w-[400px] cursor-pointer"
                    key={index}
                  >
                    <div className="flex items-center font-medium text-base">
                      <img
                        src={item?.icon}
                        alt="icon"
                        className="w-10 h-10 p-2 bg-white rounded-full mr-2"
                      />
                      {item.title}
                    </div>

                    <div className="flex justify-between items-end">
                      <h1 className="font-semibold text-4xl mt-4 flex-1">
                        {/* {item.balance} */}
                        {currencyFormatter.format(item.balance, {
                          code: "NGN",
                        })}
                      </h1>

                      {/* percentage change per day */}
                      <div className="flex items-center">
                        {/* this icon shows when the percentage of the present week transaction is lesser than the last */}
                        <KeyboardDoubleArrowDownRoundedIcon
                          color="error"
                          fontSize="large"
                        />

                        <KeyboardDoubleArrowUpRoundedIcon
                          color="success"
                          fontSize="large"
                        />
                        <span className="font-medium text-lg">
                          {item.percentageChange}%
                        </span>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>

        {/* the savings chart and customers recent transaction */}
        <div className="flex flex-wrap mt-9">
          <div className="bg-white rounded-lg">
            <PieChart />
          </div>

          <div className="rounded-lg bg-white min-h-[300px] md:ml-5 flex-1 min-w-[300px] pb-5 my-5 md:my-0 cursor-pointer">
            <h2 className="font-bold text-xl text-[#1f1f29] p-5">Top Savers</h2>

            <TopSavers navigate={navigate} />

            <p
              onClick={() => navigate("/savings")}
              className="text-base font-bold pl-5 pt-3 mt-auto text-purple-950"
            >
              View all savers
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
