/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../component/ui/SideBar";
import Header from "../../component/ui/Header";
import { InitialUserStatState } from "../../component/functions/details";
import PersonIcon from "@mui/icons-material/Person";
import VerifiedUserRoundedIcon from "@mui/icons-material/VerifiedUserRounded";
import GppBadRoundedIcon from "@mui/icons-material/GppBadRounded";
import { useNavigate, useParams } from "react-router-dom";
import getAppDetails from "../../component/api/AppDetails";
import getCustomerDetails from "../../component/api/CustomerDetails";
import { AppContext } from "../../AppContext";
// import { baseUrl } from "../component/url";
import { useCookies } from "react-cookie";
import SearchIcon from "@mui/icons-material/Search";

const Customers = () => {
  const navigate = useNavigate();
  const [userStat, setStat] = useState(InitialUserStatState);
  const { customerInfo, setCustomerInfo } = useContext(AppContext);
  const [filteredInfo, setFilteredInfo] = useState(customerInfo);
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useCookies(["token"]);
  const [searchText, setSearchText] = useState("");
  const { limit } = useParams();

  const handleSearch = (e) => {
    setSearchText(e);

    const data = customerInfo?.filter(
      (user) =>
        user?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
        user?.mail?.toLowerCase().includes(searchText.toLowerCase()) ||
        user?.username?.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredInfo(data);
  };

  useEffect(() => {
    async function fetchStat() {
      try {
        await getAppDetails(token?.token)
          .then((data) => {
            if (data.success) {
              setStat((prevStat) => {
                return prevStat.map((statItem) => {
                  switch (statItem.title) {
                    case "Total registered users":
                      return {
                        ...statItem,
                        value: data.message.total_registered_user,
                      };
                    case "Verified users":
                      return { ...statItem, value: data.message.verified_user };
                    case "Unverified users":
                      return {
                        ...statItem,
                        value: data.message.unverified_user,
                      };
                    default:
                      return statItem;
                  }
                });
              });
            } else {
              console.log(data.message);
            }
          })
          .catch((error) => console.info(error));
      } catch (error) {
        console.log(error.message);
      }
    }

    async function getCustomers() {
      try {
        await getCustomerDetails(limit, token?.token)
          .then((data) => {
            if (data.success) {
              setCustomerInfo(data.message);
              setFilteredInfo(data.message);
            }
          })
          .catch((error) => console.info(error));
      } catch (error) {
        console.error(error.message);
      }
    }

    fetchStat();
    getCustomers();
  }, []);

  return (
    <div className="flex h-screen relative overflow-y-hidden">
      <Sidebar />

      <div className="flex-1 bg-gray-200 p-5 pt-6 overflow-y-auto">
        <Header title={"Users"} />

        {/* metrics */}
        <div className="mt-10 flex md:gap-x-3 flex-wrap border-b-white border-b-[1px] pb-10">
          {userStat.map((user, index) => {
            return (
              <div
                key={index}
                className="group p-5 bg-[#00000020] rounded-xl w-full my-2 md:w-auto md:max-w-[400px] md:min-w-[250px] transition-all duration-300 ease-in-out hover:bg-[#220d3d] :"
              >
                <p className="font-semibold group-hover:text-white  transition-all duration-300 ease-in-out">
                  {user?.title}
                </p>
                <h1
                  style={{
                    color:
                      index === 1 ? "green" : index === 2 ? "red" : "#1f1f29",
                  }}
                  className="text-xl w-[70px] h-[70px] mt-3 text-[#1f1f29] flex justify-center items-center rounded-full bg-white font-bold"
                >
                  {user?.value}
                </h1>
              </div>
            );
          })}
        </div>

        {/* Registered users */}
        <div className="flex justify-between items-center py-5 md:pt-10 sticky -top-6 backdrop-blur-lg flex-wrap ">
          <h2 className="font-semibold text-[#1f1f29] text-2xl flex-1">
            Registered users
          </h2>

          {/* filter */}
          <div className="bg-white rounded-md pr-3">
            <select className="p-[13px] font-medium rounded-md focus:outline-none">
              <option className="text-base font-medium text-gray-600 my-1">
                All
              </option>
              <option className="text-base font-medium text-gray-600 my-1">
                Verified
              </option>
              <option className="text-base font-medium text-gray-600 my-1">
                Unverified
              </option>
            </select>
          </div>

          <div className="basis-[100%] md:basis-auto md:ml-3 flex items-center mt-3 md:mt-0">
            <SearchIcon
              className="text-gray-500 outline-1 relative left-8 top-0"
              fontSize="medium"
            />

            <input
              type="search"
              placeholder="Search user by name, email, username"
              className="rounded-md py-3 block px-4 pl-8 w-full"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
        </div>

        {/* the list of users */}
        <div className="mt-1 bg-white rounded-2xl p-4  overflow-auto">
          {/* list of the registered users */}
          <div className="hidden md:flex justify-between py-3 font-semibold text-base place-content-center text-center">
            <p className="ml-5">Avatar</p>
            <p className="basis-1/5">Name</p>
            <p className="basis-1/4">Username</p>
            <p className="basis-1/5">Mail</p>
            <p className="basis-1/6">Status</p>
          </div>

          <div>
            {filteredInfo.map((user, index) => {
              return (
                <div
                  onClick={() => navigate(`/details/${user.id}`)}
                  key={index}
                  about="the users list"
                  className="md:flex  justify-between items-center py-2 px-5 bg-white rounded-xl my-2 font-medium hover:bg-[#f0f0f0] transition-all duration-300 ease-in-out border-[#f2f2f2] border-2"
                >
                  {/* users image */}
                  <div className="bg-[#f0f0f0] h-10 w-10 rounded-lg flex justify-center items-center basis-[60px] mr-3">
                    {user.image ? (
                      <img
                        src=""
                        className="h-10 w-10 object-contain rounded-lg"
                        alt="user's-avatar"
                      />
                    ) : (
                      <PersonIcon fontSize="large" />
                    )}
                  </div>

                  {/* name */}
                  <p className="basis-1/5 md:text-center my-1 md:my-0">
                    {user?.name}
                  </p>

                  {/* username */}
                  <p className="basis-1/5 md:text-center my-1 md:my-0">
                    {user?.username}
                  </p>

                  {/* mail */}
                  <p className="basis-1/4 md:text-center my-1 md:my-0">
                    {user?.mail}
                  </p>

                  {/* verification status */}
                  <div className="md:flex items-center justify-end basis-1/6 my-1 md:my-0">
                    <span className="md:ml-3">
                      {user?.verified ? "Verified" : "Unverified"}
                    </span>
                    {user?.verified ? (
                      <VerifiedUserRoundedIcon
                        sx={{ fontSize: "20px" }}
                        color="success"
                      />
                    ) : (
                      <GppBadRoundedIcon
                        sx={{ fontSize: "22px" }}
                        color="error"
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* By SAM */}
        <div className="mt-5 flex gap-2 justify-between font-semibold">
          {/* previous btn */}
          {limit > 10 && ( //Show the back button only when the limit param in greater than 10
            <div
              className="bg-gray-300 rounded-[5px] p-4 transition-all duration-300 ease-in-out hover:bg-[#220d3d] hover:text-white cursor-pointer"
              onClick={() => {
                {
                  limit && !isNaN(limit)
                    ? (window.location.href = `/customers/${
                        parseInt(limit) - 10
                      }`)
                    : (window.location.href = `/customers`);
                }
              }}
            >{`< Show less`}</div>
          )}

          {/* Next btn */}
          <div
            className="bg-gray-300 rounded-[5px] p-4 transition-all duration-300 ease-in-out hover:bg-[#220d3d] hover:text-white cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              {
                limit && !isNaN(limit)
                  ? (window.location.href = `/customers/${
                      parseInt(limit) + 10
                    }`)
                  : (window.location.href = `/customers/20`);
              }
            }}
          >
            {`Show More >`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
