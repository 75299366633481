import pennyIcon from "../../assets/images/penny-icon.png";
import safeboxIcon from "../../assets/images/safebox-icon.png";
import treasureIcon from "../../assets/images/treasure-icon.png";
import stashIcon from "../../assets/images/stash-icon.png";
import lockIcon from "../../assets/images/lock-icon.png";

export const initialStateWallet = [
  {
    id: 0,

    title: "Total balance",
    icon: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTlq4XdTRZqI7LPvKQR9nkgXqdkQItzeJvow&usqp=CAU",
    balance: 0.0,
    percentageChange: 20,
    bg: "#fff",
  },
  {
    id: 1,
    title: "Penny",
    icon: pennyIcon,
    balance: 0.0,
    percentageChange: 20,
    bg: "#40009530",
  },
  {
    id: 2,
    title: "Safe Box",
    icon: safeboxIcon,
    balance: 0.0,
    percentageChange: 20,
    bg: "#00000030",
  },
  {
    id: 3,
    title: "Treasure",
    icon: treasureIcon,
    balance: 0.0,
    percentageChange: 20,
    bg: "#EA532230",
  },
  {
    id: 4,
    title: "Stash",
    icon: stashIcon,
    balance: 0.0,
    percentageChange: 20,
    bg: "#6E578630",
  },
  {
    id: 5,
    title: "Lock",
    icon: lockIcon,
    balance: 0.0,
    percentageChange: 20,
    bg: "#6E578630",
  },
];

export const InitialUserStatState = [
  {
    title: "Total registered users",
    value: 0,
  },

  {
    title: "Verified users",
    value: 0,
  },

  {
    title: "Unverified users",
    value: 0,
  },
];

export const customerInfoInitialState = [
  // {
  //   id: "tfyfjhy",
  //   name: "Audu joshua iremide",
  //   mail: "aqfeqyammiwa2gmail.com",
  //   username: "speiger",
  //   image: null,
  //   phoneNumber: "0806225728",
  //   verified: true,
  //   signupDate: "july, 10 2023, 10:23am",
  // },
  // {
  //   id: "tghhfhyu",
  //   name: "Juious hua ajayi",
  //   mail: "akeretomiwa2182gmail.com",
  //   username: "coderiger",
  //   image: null,
  //   phoneNumber: "0806225728",
  //   verified: false,
  //   signupDate: "March, 24 2023, 6:23pm",
  // },
];
